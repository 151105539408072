import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from 'styled-components'

// import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundWrapper = styled.section`
  width:100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({theme}) => theme.media.laptop} {
    display: grid;
    grid-template-columns: 1fr 45%;
  }
`;

const TextBox = styled.div`
  position: relative;
  width: 100%;
  padding: 2rem;
  text-align: center;

  ::before {
    position: absolute;
    content: '';
    z-index: -1;
    top: -20%;
    left: 0;
    height: 140%;
    width: 50%;
    background: rgba(70,186,232, .2);
  }

  ::after {
    position: absolute;
    content: '';
    z-index: -1;
    bottom: -40%;
    right: 0;
    height: 40%;
    width: 50%;
    background: rgba(232,222,46, .2);
  }

  ${({theme}) => theme.media.laptop} {
    transform: translateX(-5%);
  }  
`;

const H1 = styled.h1`
  font-size: 6rem;
  font-weight: 700;

  ${({theme}) => theme.media.laptop} {
    font-size: 9rem;
  }
`;

const Text = styled.p`
  font-size: 1.3rem;
`;

const ImgBox = styled.div`
  display: none;

  ${({theme}) => theme.media.laptop} {
    display: block;
    width: 100%;
    height: 60vh;

    div {
      height: 100%;
    }
  }
`;


const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      lostImg: file(relativePath: { eq: "lost-site.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO 
        title="404: Nie ma takiej strony" 
      />

     <NotFoundWrapper>
      <ImgBox>
        <Img fluid={data.lostImg.childImageSharp.fluid} alt="zagubiony na pustyni"/>
      </ImgBox>
      <TextBox>
        <H1>404</H1>
        <Text>wyglada na to że się zgubiłeś :(</Text>
      </TextBox>
     </NotFoundWrapper>

    </>
  )
}

export default NotFoundPage

